import { useEffect } from "react";
import useQualifierContext from "../../context/qualifiers/hooks/useQualifierContext";
import { SubserviceHome } from "../../pages/Subservices/components/SubserviceHome";
import { Service } from "../../context/qualifiers/types/StateType";

interface SubserviceWrapperProps {
	config: Service;
}

export default function SubserviceWrapper({ config }: SubserviceWrapperProps) {
	const { dispatch, setFilterLevel } = useQualifierContext();
	useEffect(() => {
		dispatch({
			type: "SET_SERVICE",
			payload: config,
		});
		if (config.filterLevel) setFilterLevel(config.filterLevel);
	}, []);

	return (
		<SubserviceHome
			id={config.id}
			name={config.name}
			parentId={config.parentId}
			typeId={config.typeId}
			homeComponent={config.homeComponent}
			hideProductLevel={config.hideProductLevel}
		/>
	);
}

import { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../../pages/Home";
import { Products } from "../../pages/Products";
import { Employers } from "../../pages/Employers";
import { Map } from "../../pages/Map";
import { About } from "../../pages/About";
import { RouterProps } from "./types/RouterProps";
import { Subservice } from "../../components/Subservice";
import { NotFound } from "../../pages/NotFound";
import { subRoutes } from "./subRoutes";
import ScrollToTop from "../../components/ScrollToTop";

export const Router: FC<RouterProps> = ({ setSubserviceLoaded }) => {
	return (
		<ScrollToTop>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/products/*" element={<Products />} />
				<Route path="/employers" element={<Employers />} />
				<Route path="/map" element={<Map />} />
				<Route path="/about" element={<About />} />

				{/* LEGACY SUBSERVICES */}
				{subRoutes.map((route, index) => (
					<Route
						key={`${route}-${index}`}
						path={route}
						element={
							<Subservice
								setSubserviceLoaded={setSubserviceLoaded}
							/>
						}
					/>
				))}

				{/* CATCHALL 404 COMPONENT */}
				<Route path="*" element={<NotFound />} />
				<Route path="/not-found" element={<NotFound />} />
			</Routes>
		</ScrollToTop>
	);
};

import BjsHome from "./pages/Subservices/BJS/Home";
import CpwmHome from "./pages/Subservices/CPWM/Home";
import PjwHome from "./pages/Subservices/PJW/Home";
import ApssHome from "./pages/Subservices/APSS/Home";
import BocHome from "./pages/Subservices/BOC/Home";
import { Service } from "./context/qualifiers/types/StateType";

export const serviceIds = JSON.parse(process.env.REACT_APP_SERVICES || "{}");
interface Services {
	[key: string]: Service;
}

const services: Services = {
	mfbt: {
		id: serviceIds.mfbt.id,
		name: "mfbt",
		parentId: serviceIds.mfbt.parentId,
		fullName: "My Food & Bev Training",
		typeId: 1,
		hideProductLevel: false,
	},
	bjs: {
		id: serviceIds.bjs.id,
		name: "bjs",
		parentId: serviceIds.bjs.parentId,
		fullName: "BJ's Wholesale Club",
		typeId: 3,
		filterLevel: 1,
		homeComponent: BjsHome,
		hideProductLevel: false,
	},
	cpwm: {
		id: serviceIds.cpwm.id,
		name: "cpwm",
		parentId: serviceIds.cpwm.parentId,
		fullName: "World Market",
		typeId: 4,
		filterLevel: 1,
		homeComponent: CpwmHome,
		hideProductLevel: false,
	},
	pjwrg: {
		id: serviceIds.pjwrg.id,
		name: "pjwrg",
		parentId: serviceIds.pjwrg.parentId,
		fullName: "PJW Restaurant Group",
		typeId: 6,
		filterLevel: 1,
		homeComponent: PjwHome,
		hideProductLevel: true,
	},
	aplus: {
		id: serviceIds.aplus.id,
		name: "aplus",
		parentId: serviceIds.aplus.parentId,
		fullName: "A+ Staffing",
		typeId: 7,
		filterLevel: 1,
		homeComponent: ApssHome,
		hideProductLevel: true,
	},
	boc: {
		id: serviceIds.boc.id,
		name: "boc",
		parentId: serviceIds.boc.parentId,
		fullName: "Brewer Oil Company",
		typeId: 8,
		filterLevel: 1,
		homeComponent: BocHome,
		hideProductLevel: false,
	},
};

export default services;
